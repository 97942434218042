<template>
  <li :class="['carousel-slide', 'transition']">
    <slot :slideScope="slideScope"></slot>
  </li>
</template>

<script>
import { getInputs } from '../../helpers/focus-helpers'
import mixinResponsive from '../../mixins/mixin-responsive'

export default {
  name: 'carousel-slide',

  mixins: [mixinResponsive],

  props: {
    slidesPerFrame: {
      default: () => [1,1,1,1],
      type: Array
    },
    marginSize: {
      default: 10,
      type: Number
    }
  },

  data () {
    return {
      slideScope: {},
      isActive: false,
      inputElements: []
    }
  },

  created () {
    //change breakpoint for carousel as text doesn't fit
    this.breakpoints.medium = 1200
    this.breakpoints.large = 2000
  },

  mounted () {
    this.setSlideStyle()
    this.inputElements = getInputs(this.$el)
    this.setTabIndices()
  },

  watch: {
    isActive () {
      this.setTabIndices() 
    },

    currentBreakpoint () {
      this.setSlideStyle()
    }
  },

  methods: {
    getSlidesPerFrame() {
      switch (this.currentBreakpoint) {
      case 'mobile':
        return this.slidesPerFrame[0]
      case 'tablet':
        return this.slidesPerFrame[1]
      case 'laptop':
        return this.slidesPerFrame[2]
      case 'desktop':
        return this.slidesPerFrame[3]
      }
    },

    setTabIndices() {
      const tabIndex = this.isActive ? 0 : -1

      Array.prototype.forEach.call(this.inputElements, el => {
        el.tabIndex = tabIndex
        if(tabIndex === -1) { el.blur() }
      })
    },

    setSlideStyle () {
      const style = this.$el.style

      style.marginLeft = style.marginRight = this.marginSize + 'px'
      style.width = `calc(${100/this.getSlidesPerFrame()}% - ${2*this.marginSize}px)`
    }
  }
}
</script>