import ScrollMagic from 'scrollmagic'
import ComponentScenes from './component-scenes'

const getCompHeaderOffset = () => {
  return document.querySelector('#sc-component-header').offsetHeight - window.innerHeight
}

const OUTLOOK_SCENE_CONFIG = {
  id: 'sc-outlook',
  pin: 'sc-outlook',
  offset: -1,
  overrideDuration: 0
}

const COMPONENT_SCENES_CONFIG = [
  {
    id: 'sc-component-header',
    subScenes: [
      {
        id:'sc-component-header-bg',
        pin: 'sc-component-header-bg',
        offset: getCompHeaderOffset
      }
    ]
  },
  {
    id: 'sc-challenge-and-solution',
    subScenes: [
      {
        id: 'sc-challenge-header',
        pin: 'sc-challenge-header',
        offset: 1
      },
      {
        id: 'sc-challenge-first-content',
        pushFollowers: true,
        pin: 'sc-challenge-first-content'
      }
    ]
  },
  {
    id: 'sc-outputs',
    subScenes: [
      {
        id: 'sc-outputs-header',
        pin: 'sc-outputs-header',
        offset: 1
      },
      {
        id: 'sc-outputs-first-content',
        pushFollowers: true,
        pin: 'sc-outputs-first-content'
      }
    ]
  }
]

const sideImageScenesConfig = [
  // {
  //   id: 'sc-challenge-side-image'
  // },
  // {
  //   id: 'sc-outputs-side-image'
  // }
]

const getComponentScenesConfig = () => {
  const componentScenesConfig = COMPONENT_SCENES_CONFIG

  if (document.querySelector('#sc-outlook')) {
    componentScenesConfig.push(OUTLOOK_SCENE_CONFIG)
  }

  return componentScenesConfig
}

export default () => {  
  const navController = new ScrollMagic.Controller
  
  new ScrollMagic.Scene({
    triggerHook: 0,
    offset: 1
  }).setClassToggle('#nav', 'nav--background')
    .addTo(navController)

  if (document.querySelector('#scrollmagic-container')) { 
    const componentScenes = new ComponentScenes({
      scenesConfig: getComponentScenesConfig(),
      sideImageScenesConfig,
      footerId: 'sc-related-resources'
    })

    componentScenes.addScenesAndFooterMargin()
    window.addEventListener('resize', componentScenes.refresh.bind(componentScenes))
  }
}