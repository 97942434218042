import ScrollMagic from 'scrollmagic'

export default class ComponentScenes {
  constructor({scenesConfig, sideImageScenesConfig, footerId}) {
    this.controller = new ScrollMagic.Controller()
    this.scenesConfig = scenesConfig
    this.sideImageScenesConfig = sideImageScenesConfig
    this.footerId = footerId

    this.updateAllScenesConfig()
  }

  refresh () {
    this.controller.destroy(true)
    this.updateAllScenesConfig()

    this.controller = new ScrollMagic.Controller()
    this.addScenesAndFooterMargin()
  }

  updateAllScenesConfig () {
    this.updateSceneDurations()
    this.updateSideImageScenesConfig()
  }

  updateSideImageScenesConfig () {
    this.sideImageScenesConfig.forEach(scene => {
      setSideImageDuration(scene)
    })
  }

  updateSceneDurations () {
    let scrollYTotal = 0
  
    this.scenesConfig.forEach(scene => {
      let scrollY = scrollYTotal
      
      scrollY += document.querySelector('#' + scene.id).offsetHeight
      setDuration(scene, scrollY)
      
      if (scene.subScenes) {  
        scene.subScenes.forEach(subScene => {
          setDuration(subScene, scrollYTotal)
        })
      }
      
      scrollYTotal = scene.duration
    })
  }

  addScenesAndFooterMargin () {
    this.scenesConfig.forEach(scene => {
      if (scene.pin) { this.addSceneWithPin(scene) }
      
      if (scene.subScenes) {
        scene.subScenes.forEach(subScene => {
          if (subScene.pin) { this.addSceneWithPin(subScene) }
        })
      }
    })

    this.sideImageScenesConfig.forEach(scene => {
      this.addSideImageScene(scene)
    })

    this.addMarginToFooter()
  }

  addSceneWithPin (scene) {
    if (scene.duration === 0) {return}
    
    new ScrollMagic.Scene({
      triggerHook:0, 
      duration: scene.getDuration
    }).setPin('#' + scene.pin, {pushFollowers: scene.pushFollowers})
      .addTo(this.controller)
  }

  addSideImageScene (scene) {
    new ScrollMagic.Scene({
      triggerElement: '#' + scene.id,
      triggerHook: getSideImageTriggerHook(scene.id),
      duration: scene.getDuration
    }).setPin('#' + scene.id, { pushFollowers: false })
      .addTo(this.controller)
  }

  addMarginToFooter () {
    const finalSceneConfig = this.scenesConfig[this.scenesConfig.length - 1]
    let margin = finalSceneConfig.duration

    if (finalSceneConfig.id === 'sc-outlook') { margin += window.innerHeight }
    document.querySelector('#' + this.footerId).style.marginTop = margin + 'px'
  }
}

const setDuration = (scene, scrollY) => {
  scene.duration = scrollY + getOffset(scene)
  scene.getDuration = scene.overrideDuration === undefined ? 
    () => scene.duration :
    scene.overrideDuration
}

const getSideImageTriggerHook = imageId => {
  const imageHeight = document.querySelector('#' + imageId).offsetHeight

  return (window.innerHeight - imageHeight) / (2 * window.innerHeight)
}

const getOffset = scene => {
  let offset = null

  if (typeof scene.offset === 'function') {
    offset = scene.offset()
  } else {
    offset = scene.offset ? scene.offset * window.innerHeight : 0
  }

  return offset
}

const setSideImageDuration = scene => {
  const el = document.querySelector('#' + scene.id)
  const imageHeight = el.offsetHeight
  
  if (imageHeight === 0) {
    scene.duration = 0
  } else {
    const containerHeightWithoutPaddingTop = getHeightWithoutPaddingTop(el.parentElement)
  
    scene.duration = containerHeightWithoutPaddingTop - (window.innerHeight + imageHeight)/2
  }
  scene.getDuration = () => scene.duration
}

const getHeightWithoutPaddingTop = (el) => {
  const computedParentStyle = getComputedStyle(el)
  const containerHeightWithoutPaddingTop = el.clientHeight 
    - parseFloat(computedParentStyle.paddingTop) 

  return containerHeightWithoutPaddingTop
}