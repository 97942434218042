import 'core-js/es6/object'

import Vue from 'vue/dist/vue.esm'
import Vue2TouchEvents from 'vue2-touch-events'

import Carousel from './vue-components/carousel/Carousel'
import CarouselSlide from './vue-components/carousel/CarouselSlide'
import ExpandableItem from './vue-components/expandable-item/ExpandableItem'
import Modal from './vue-components/modal/Modal'
import ModalTrigger from './vue-components/modal/ModalTrigger'
import SocialIcons from './vue-components/social-icons/SocialIcons'
import VNav from './vue-components/nav/VNav'

import store from './store/store'

import initScrollMagicScenes from './scrollmagic-scenes'

export const eventHub = new Vue()

document.addEventListener('DOMContentLoaded', () => {
  Vue.use(Vue2TouchEvents)
  
  new Vue({
    el: '#vue-app',
    store,
    components: { Carousel, CarouselSlide, ExpandableItem, Modal, ModalTrigger, SocialIcons, VNav }
  })
  
  initScrollMagicScenes()
})