<template>
  <div class="social-icons">
    <button 
      v-if="isMobile()"
      class="social-icons__popup-trigger" 
      @click="openPopUp"
    />
    <div
      v-if="showIcons"
      class="social-icons__container"
    >
      <button 
        v-if="isMobile()"
        class="social-icons__close button--close"
        @click="closePopUp"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import mixinResponsive from '../../mixins/mixin-responsive'

export default {
  mixins: [mixinResponsive],
  
  data () {
    return {
      isActive: false
    }
  },

  computed: {
    showIcons () {
      return !this.isMobile() || 
        (this.isMobile() && this.isActive)
    }
  },

  methods: {
    openPopUp () {
      this.isActive = true
    },

    closePopUp () {
      this.isActive = false
    }
  },
}
</script>

<style>

</style>
